import React, { useState } from "react"
import { Link,graphql } from "gatsby"

import Layout from "../components/layout"
import BreadCrumb from "../components/breadcrumb"
import Img from "gatsby-image"
import WdModal from '../components/modal'
import ModalPost from '../components/modalpost'
import Success from '../components/success'
import { GTMPhoneClick } from "../lib/gtag"

const Service = ({pageContext,path,data}) => {
  var metainfo = {
    title: pageContext.metainfo? (pageContext.metainfo.title || 'Επικοινωνία') + " | " + process.env.SITE_NAME  : (pageContext.title || 'Επικοινωνία') + " | " + process.env.SITE_NAME,
    description:pageContext.metainfo? pageContext.metainfo.description || '' : '',
    image:'/images/logo.png'
  }

  const [reveal, setReveal] = useState(false)
  const [revealsuccess,setRevealSuccess] = useState(false)

  return (
    <Layout metainfo={metainfo}>

      <WdModal setRevealSuccess={setRevealSuccess} setReveal={setReveal} reveal={reveal}/>
      <ModalPost reveal={revealsuccess} setReveal={setRevealSuccess}>
        <Success/>
      </ModalPost>

      <BreadCrumb title={pageContext.title}/>

      <div className="service-details-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-7">

              <div className="single-service">
                <h2>Ενοικίαση Αναβατόριου</h2>
                <p>
                  Η εταιρία μας  αναλαμβάνει με συνέπεια οποιαδήποτε εργασία ανύψωσης χρειαστείτε. Εγγυόμαστε την ασφαλέστερη μεταφορά οποιουδήποτε βαρέως αντικειμένου όπως επίπλων και οικοσκευών, χωρίς φυσικά ανεπιθύμητα χτυπήματα κατά την μεταφορά τους.Μπορούμε να προσεγγίσουμε οποιοδήποτε δρόμο της Αττικής, χωρίς κανένα απολύτως πρόβλημα καθώς το οχήμα μας είναι μικρό σε όγκο ώστε να μπορεί με άνεση να κινηθεί ακόμα και τους πιο στενούς δρόμους στο κέντρο της Αθήνας.Παρέχουμε αδιαπραγμάτευτη ποιότητα στις υπηρεσίες μας στην καλύτερη δυνατή τιμή. Επικοινωνήστε τώρα μαζί μας ώστε να βρούμε την καλύτερη λύση για εσάς.
                </p>

              </div>

            </div>

            <div className="col-md-5">
              <div className="row">

                { data.anabatorio.edges.map((item) => {
                  return (
                    <div className="col-12 my-2">
                      <div className="single-service-bg">
                        <Img fluid={item.node.childImageSharp.fluid}
                             alt={`metakomisi`}
                        />
                      </div>
                    </div>

                  )
                })}

              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="quotation-block mt-4">
                <div className="quotation-text py-4 text-center">
                  <h4>Κόστος Ανύψωσης</h4>
                  <p>Ζητήστε μια προσφορά για τις  υπηρεσίες ανύψωσης που μπορούμε να σας παρέχουμε.</p>
                  <div className="request-button">
                    <button onClick={()=>setReveal(true)} type="button" className="btn btn-request">ΜΑΘΕ ΤΟ ΚΟΣΤΟΣ<i className="las la-arrow-right" /></button>
                  </div>
                </div>
                <div className="quotation-dtl text-white">
                  <p><i className="las la-mobile" />Μπορείτε να μας βρείτε στα τηλέφωνα: <a onClick={()=> GTMPhoneClick()} href="tel:6949873177">694 987 3177</a>, <a onClick={()=> GTMPhoneClick()} href="tel:6942073329">694 207 3329</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </Layout>
  )
}

export default Service


export const query = graphql`
  query {



   anabatorio:allFile(filter: {name: {in: ["anavatorio"]}}) {
   edges {
     node {
       childImageSharp {
         fluid(maxWidth: 500,quality:80) {
           ...GatsbyImageSharpFluid
         }
       }
     }
   }
 }


  }
`
